import React from 'react';

const Footer = () => {
    const openUrl = (url) =>  {
        console.log(url);
        debugger
        window.open(url, '_blank');
    }
    return (<footer className="footer">
        <div className="footer-top">
            <p className="title">了解更多与我们相关</p>
            <img className="back" src={require('../assets/images/back.png')} alt=""/>
        </div>
        <div className="footer-bottom">
            <p className="title">快速链接</p>
            <div className="footer-links">
                <span onClick={()=>openUrl('https://love.perfects.ai')}>注册/登录</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>ai工具</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>事无巨细</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>0%检出</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>用户评价</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>案例视频</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>精选套餐</span>
                <span onClick={()=>openUrl('https://love.perfects.ai')}>常见问题</span>
            </div>
        </div>

        <img className="logo" src={require('../assets/images/logo-footer.png')} alt=""/>
    </footer>)
}

export default Footer;