import React, {useEffect, useState} from 'react';

const list = [{
    name: '刘*',
    content: '通过使用PERFECTS.AI的AI文书服务，我在申请过程中收获颇丰，成功斩获了多份来自名校的录取通知。经过深思熟虑，我已经决定接受香港科技大学MPHIL-PHD IN SOSC项目的邀请，开启我的学术研究生涯。PERFECTS.AI的文书不仅在语言表达上精准流畅，更在内容构建上充分展现了我的学术背景和潜力，为我的申请加分不少。感谢PERFECTS.AI的专业团队。',
    identity: '港科大硕博连读项目',
    use: ''
},{
    name: '张*华',
    content: '通过PERFECTS.AI的帮助，我成功申请到了杜兰大学的奖学金，奖学金比例高达35%。一开始，我只是想试试看。没想到，他们的AI系统不仅为我量身定制了一份逻辑清晰、内容详实的申请文书，还在语言表达上做到了地道且引人入胜。这份文书不仅展示了我的学术成就和背景，还充分表达了我对奖学金的渴望以及未来的学术规划。',
    identity: '杜兰大学35%奖学金申请',
    use: ''
},{
    name: '田*',
    content: '通过PERFECTS.AI的文书服务，成功申请到了纽约大学学院的硕士项目。一开始，我对文书制作有点不知所措。但PERFECTS.AI的文书服务让我感到安心。他们的每一步流程都精细明确，从个人经历的梳理、学术背景的提炼，到文书的撰写和修改，都有明确的步骤。这使得我原本觉得困难重重的文书制作变得迎刃而解。',
    identity: '纽约大学硕士项目申请',
    use: ''
},{
    name: '冯*娜',
    content: '在成功申请美国西北大学这一重要的人生阶段，我使用了PERFECTS.AI的专家润色服务，他们不仅为我提供了高质量的文书修改，更在申请过程中给予了我极大的信心和支持。PERFECTS.AI的专家团队对我的初稿进行了细致的审阅和修改，不仅在语言表达上更加流畅自然，更在逻辑结构上进行了优化，使得整篇文书更加紧凑有力。',
    identity: 'NU传媒硕士申请',
    use: ''
}]
const Testimonials = () => {
    const [data, setData] = useState(list.concat(list));
    useEffect(() => {
        setTimeout(()=> {
            setData(list.concat(list))
        }, 10000)
    }, []);
    useEffect(() => {
        const testimonialsContainer = document.querySelector('.scrolling-testimonials');

        const scrollInterval = setInterval(() => {
            testimonialsContainer.scrollLeft += 1; // 每次向右滚动1像素
        }, 50); // 每50毫秒滚动一次

        // 清除定时器
        return () => clearInterval(scrollInterval);
    }, []);

    return (
        <section className="testimonials">
            <h2>使用者<span className="highlight">好评</span>如潮</h2>
            <p className="description">Perfects.AI的文书产品已经被海量学生、留学机构和老师所采用，并给予高度认可。
                我们的文书已经递交到数百所院校，并获得百余封世界顶级名校Offer。</p>
            <div className="scrolling-testimonials">
                {data.map((item, index)=> (<div className="testimonial" key={index}>
                    <p className="name">{item.name}</p>
                    <p className="identity">{item.identity}</p>
                    <p className="use">{item.use}</p>
                    <p className="content">{item.content}</p>
                </div>))}
            </div>
        </section>
    );
};

export default Testimonials;