import React from 'react';
const Finished = require('../assets/images/icon-finished.png');
const Icon = require('../assets/images/icon-success.png');
const steps = ['开始','申请信息','学术背景','学术经历','职业经历','职业计划','申请动机','风格确认','故事线构思','文书确认','完成'];
const moduleList = [{
    title: '24小时在线无限次修改',
    content: 'Perfects.AI文书系统支持多轮精修，通过独家打造的人机交互功能，我们的AI系统会随时陪伴，帮助学生不断优化内容。从大纲比重调整到逐字逐句精修，无论是修改、扩展还是缩减，学生都可以轻松实现，使文书始终保持最佳状态。'
},{
    title: '动机扣人心弦',
    content: 'Perfects.AI文书系统支持多轮精修，通过独家打造的人机交互功能，我们的AI系统会随时陪伴，帮助学生不断优化内容。从大纲比重调整到逐字逐句精修，无论是修改、扩展还是缩减，学生都可以轻松实现，使文书始终保持最佳状态。'
},{
    title: '文书量身打造',
    content: 'Perfects.AI文书系统支持多轮精修，通过独家打造的人机交互功能，我们的AI系统会随时陪伴，帮助学生不断优化内容。从大纲比重调整到逐字逐句精修，无论是修改、扩展还是缩减，学生都可以轻松实现，使文书始终保持最佳状态。'
},{
    title: '经历精准挑染',
    content: 'Perfects.AI文书系统支持多轮精修，通过独家打造的人机交互功能，我们的AI系统会随时陪伴，帮助学生不断优化内容。从大纲比重调整到逐字逐句精修，无论是修改、扩展还是缩减，学生都可以轻松实现，使文书始终保持最佳状态。'
}]
const ProcessSteps = () => {

    const openUrl = (url) =>  {
        console.log(url);
        window.open(url, '_blank');
    }
    return (
        <section className="process-steps">
            <h2>每一道流程精准把握，<br/><span className="highlight">事无巨细地</span>从0打造精致文书</h2>
            <div className="step-bar">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className="step-icon">
                            <img className="icon" src={index === steps.length - 1 ? Finished : Icon}/>
                            <div className="text">{step}</div>
                        </div>
                        {index !== steps.length - 1 && <div className="connector"/>}
                    </React.Fragment>
                ))}
            </div>
            <div className="modules">
                {moduleList.map((module, index) => (
                    <div className="module" index={index}>
                        <img src={require(`../assets/images/module-${index + 1}.png`)} alt=""/>
                        <div className="content">
                            <h3>{module.title}</h3>
                            <p>{module.content}</p>
                            <a href="https://love.perfects.ai" target={'_blank'}>了解更多</a>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={() => openUrl('https://love.perfects.ai')}>查看更多流程</button>
        </section>
    );
};

export default ProcessSteps;