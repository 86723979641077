import LOGO from '../assets/images/logo.png'
const Navbar = () => {
    const openUrl = (url) =>  {
        console.log(url);
        window.open(url, '_blank');
    }
    return (<nav className="navbar">
        <div className="content">
            <img className="logo" src={LOGO} alt=""/>
            <div className="login-buttons">
                <button className="btn" onClick={()=>openUrl('https://love.perfects.ai')}>登入</button>
                <button className="btn-register" onClick={()=>openUrl('https://love.perfects.ai/signup')}>注册</button>
            </div>
        </div>
    </nav>)
}

export default Navbar;