import React, {useState, useRef, useEffect} from 'react';
import CustomVideo from './CustomVideo';
const menu = ['素材头脑风暴', '个性化参数', '故事线设计', '板块比重修改', '专家润色系统', '自定义编辑', 'AI降重'] // '产品案例.mp4'

const href = "https://www.perfects.ai/video/";
const AITools = () => {
    const videoRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState(href+menu[0]+'.mp4');
    const onMouseMove = (index) => {
        setVideoUrl(`${href}${menu[index]}.mp4`)
    }

    const openUrl = (url) => {
        console.log(url);
        window.open(url, '_blank');
    }

    return (
        <section className="ai-tools">
            <h2><span className="highlight">AI工具</span>助你自在掌握文书全局</h2>
            <div className="content">
                <div className="menu">
                    {menu.map((item, index) => <div className="item"
                        // onMouseMove={() => onMouseMove(index)} 
                        onClick={() => onMouseMove(index)}>{item}</div>
                    )}
                </div>
                <CustomVideo
                    pref={videoRef}
                    videoSrc={ videoUrl || "https://www.w3schools.com/html/mov_bbb.mp4"}
                    poster={require('../assets/images/poster2.png')}
                />
            </div>
            <button className="btn" onClick={()=>openUrl('https://love.perfects.ai')}>仅需399￥起即可使用以上所有权限</button>
        </section>
    )
}

export default AITools;