import React, {useEffect,useState} from 'react';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import AITools from './components/AITools';
import ProcessSteps from './components/ProcessSteps';
import DetectionRate from './components/DetectionRate';
import Testimonials from './components/Testimonials';
import CaseVideo from './components/CaseVideo';
import Packages from './components/Packages';
import FAQs from './components/FAQs';
import Footer from './components/Footer';

function App() {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // 初始加载时检测一次
        handleResize();

        // 添加窗口大小变化监听器
        window.addEventListener('resize', handleResize);

        // 清除监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  return (
      <div className={`app ${isMobile ? 'mobile' : ''}`}>
          <Navbar/>
          <HeroSection/>
          <AITools/>
          <ProcessSteps/>
          <DetectionRate/>
          <Testimonials/>
          <CaseVideo/>
          <Packages/>
          <FAQs/>
          <Footer/>
      </div>
  );
}

export default App;