import React, { useRef } from "react";
import CustomVideo from "./CustomVideo";

const CaseVideo = () => {
  const videoRef = useRef(null);
  return (
    <section className="case-video">
      <h2>
        <span className="highlight">案例</span>视频
      </h2>
      {/* <video className="video" poster={require('../assets/images/poster.png')}>
            <source src= "https://www.perfects.ai/video/产品案例.mp4" type="video/mp4" />
            您的浏览器不支持 video 标签。
        </video> */}
      <div className="videoWarp">
        <CustomVideo
          pref={videoRef}
          videoSrc="https://www.perfects.ai/video/产品案例.mp4"
          poster={require("../assets/images/poster.png")}
        />
      </div>
    </section>
  );
};

export default CaseVideo;
