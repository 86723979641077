import React, { useState } from "react";
const img1 = require("../assets/images/rate-1.png");
const img2 = require("../assets/images/rate-2.png");
const img3 = require("../assets/images/rate-3.png");
const Icon = require("../assets/images/icon-success.png");
const DetectionRate = () => {
  const [selectedImage, setSelectedImage] = useState(1);

  const handleMouseOver = (index) => {
    setSelectedImage(index);
  };
  return (
    <section className="detection-rate">
      <h2>
        <span className="highlight">0%</span>AI检出率
      </h2>
      <div className="images">
        <div className="image" onMouseOver={() => handleMouseOver(0)}>
          <img
            className={`img1 ${selectedImage === 0 ? "zoomed" : "normal"}`}
            src={img1}
            alt=""
          />
        </div>
        <div className="image" onMouseOver={() => handleMouseOver(1)}>
          <img
            className={`img2 ${selectedImage === 1 ? "zoomed" : "normal"}`}
            src={img2}
            alt=""
          />
        </div>
        <div className="image" onMouseOver={() => handleMouseOver(2)}>
          <img
            className={`img3 ${selectedImage === 2 ? "zoomed" : "normal"}`}
            src={img3}
            alt=""
          />
        </div>
      </div>
      <div className="texts">
        <div className="text">
          <img className="icon" src={Icon} alt="" />
          <p>ZeroGPT</p>
        </div>
        <div className="text">
          <img className="icon" src={Icon} alt="" />
          <p>Turnitin</p>
        </div>
        <div className="text">
          <img className="icon" src={Icon} alt="" />
          <p>GPTZero</p>
        </div>
      </div>
      <p className="description">
        我们独家优化的AI算法能够帮助学生自动进行文书的降重处理，确保在AI检测算法无法查出的情况下，保持文书原意和质量，并最大化文书语言水平。AI降重后的文书，可以达到0%的AI检测率，被标记为“人类撰写”，可拿来即用。
        <br />{" "}
        确保⽂书在⼤学官⽅使⽤的查重平台Turnitin，以及市场上最严格且主流的AI检测平台GPTZero、ZeroGPT等保持极低的AI检出率。
      </p>
    </section>
  );
};

export default DetectionRate;
