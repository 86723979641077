import React, { useState } from "react";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const questions = [
    {
      question:
        "1/ AI生成的文书是否足够个性化，能否准确表达我的独特经历和优势？",
      answer:
        "解答：Perfects文书系统通过故事线预设计功能，为用户提供多种叙事逻辑和结构选择。这种设计让用户可以选择适合自己背景和经历的文书模板，帮助快速梳理自己的独特故事。此外，工具允许自定义参数，例如文书的结构、语气、个性优势展示和板块比重等，确保文书的风格和内容与用户的个人经历紧密贴合。同时，系统支持多版本文书生成，用户可以生成多个版本的文书，比较不同的叙述方式，并选择最能展现个人独特优势的版本。这一特性尤其适合那些拥有复杂经历的用户，他们可以通过不同的表达方式探索最佳的呈现方式。",
    },
    {
      question: "2/ AI生成的文书质量是否足够高，能否满足我的申请需求？",
      answer:
        "解答：Perfects文书系统不仅能生成高质量的文书，还配备了留学专家共同打造的AI专家评估和润色功能，用户可以通过AI评估系统对文书进行修改，确保其语言表达符合尖端要求。同时，用户还可以选择让人类专家进行最后的润色编辑，确保文书达到院校审查的高标准。Perfects编辑工具提供多种编辑方式，如自定义生成、扩展、缩短等，用户可以反复打磨文书内容。这样不仅能提升文书的整体质量，还能确保用户在不满意的情况下能灵活调整",
    },
    {
      question: "3/ 我对使用AI不太有经验，AI文书工具是否易于使用？",
      answer:
        "解答：Perfects文书系统采用了模块化输入，将文书分成多个板块，用户可以逐步完成内容填写，避免一次性完成的压力。每个板块还配有视频指导、文字说明以及其他用户的示例，帮助用户清晰理解填写要求。用户可以在填写过程中随时保存进度，随时返回编辑，操作简单且灵活。这种设计让用户在没有太多技术背景的情况下也能轻松上手，并能在自己的节奏下完成整个文书。",
    },
    {
      question: "4/ AI系统是否能够理解我的背景独特性？",
      answer:
        "解答：Perfects文书系统允许用户根据自己的背景和申请目标进行自定义输入，包括文化背景、职业目标和申请院校等相关信息。通过多版本生成，用户可以选择最适合自己背景的表达方式，确保文书充分体现个人的独特需求。工具中的故事线设计和板块自定义功能可以适应不同文化背景和专业领域的需求，确保文书不仅符合通用标准，还能针对不同的申请场景进行个性化定制。",
    },
    {
      question:
        "5/ AI生成的内容是否能通过学校的检测系统，是否会被怀疑为抄袭、做假或模板化？",
      answer:
        "解答：Perfects文书系统独家优化的AI降重功能，确保低于10%的AI检测率，通常检测率逼近于0%。我们通过独特的算法确保文书不会被检测系统标记为抄袭或模板化。用户可以通过系统的降重功能使文书更加自然和原创，确保其能够通过学校的检测系统，不会被怀疑为抄袭或使用AI工具。系统通过humanize处理，也会使文书看起来更加人性化，既保留了AI的高效生成能力，也确保内容符合院校对个性化表达的要求。",
    },
    {
      question: "6/ AI文书的价格比普通留学机构有多大优势？",
      answer:
        "解答：相较于传统留学中介的高昂价格，Perfects文书系统提供了更具经济性的选择。工具的价格远低于中介费用，但提供的服务质量并不逊色，尤其是结合了AI生成与人工润色的双重优势，适合那些预算有限但仍希望获得高质量文书的用户，不花一分冤枉钱。工具提供了从基础版到高级版的灵活选择，用户可以根据自己的需求和预算选择适合的服务。无论是简单的AI生成文书还是结合人工润色的高级服务，性价比都非常高。我们的AI文书服务已经销售给众多中大型留学机构，侧面证明学生们在网站上生成的AI文书也可以达到中介服务的水准。",
    },
    {
      question:
        "7/ AI工具能否提供持续的支持，尤其是在申请过程中遇到修改需求时？",
      answer:
        "解答：Perfects文书系统提供7*24小时在线服务，用户在申请过程中遇到修改需求时，可以随时使用工具进行调整。无论是生成新的版本、缩短或扩展内容，用户都可以灵活修改文书，确保申请过程中得到持续的支持 (Perfects 文书产品特性&营销思路)。用户也可以在文书的各个阶段使用层层精修升级功能，不断优化内容，直到最终满意为止。这种灵活的支持确保了用户在整个申请过程中都能随时调整文书。",
    },
    {
      question:
        "8/ AI生成的文书会不会存在语法或语言风格上的问题，影响申请效果？",
      answer:
        "解答：Perfects文书系统内置了专家打造的AI文书评估润色系统，能够自动检测并修正语法、拼写或语气上的问题，确保文书的语言表达没有错误。此外，用户还可以选择人工润色服务，由专业的语言专家进行最后的审阅和优化，确保文书的语言风格符合目标院校的要求。通过AI生成与专家润色相结合的方式，文书既能保持逻辑清晰和表达流畅，又能符合学术和语言标准，确保在申请中的竞争力。",
    },
  ];

  // const questions = [
  //     { question: "AI⽣成的⽂书是否⾜够个性化，能否准确表达我的独特经历和优势？", answer: "解答：我们的AI⽂书产品通过故事线（narrative预设计）和⾃定义参数功能，允许⽤户选择不同的结构、语⽓和重点板块，以确保⽂书能够反映他们的个性化需求。产品还⽀持同⼀篇⽂书多版本⽣成，让⽤户能够看到不同的表达⽅式和叙述⽅式，帮助他们选择最能体现个⼈风格的版本。" },
  //     { question: "AI⽣成的⽂书质量是否⾜够⾼，能否通过严格的审查？", answer: "解答：我们的AI⽂书产品不仅能够⽣成⾼质量的⽂书，还通过专家评估精修和⼈⼯润⾊编辑功能确保最终输出的⽂书达到专业⽔准。⽤户可以随时选择AI⽣成的建议，也可以忽略或调整，确保⽂书符合严格的标准和个性化需求。" },
  //     { question: "AI⼯具是否易于使⽤，我不确定我能熟练操作复杂的功能？", answer: "解答：我们的产品设计充分考虑了⽤户的体验，整个过程是模块化的，并且每个信息采集板块都 配有专家录制的指导视频、⽂字指导以及其他⽤户填写的⽰例sample。此外，产品⽀持随时保存进度和返回编辑，不需要⼀次性完成填写，⽤户可以在⾃⼰的节奏下逐步完成⽂书。 " },
  //     { question: "AI是否能够理解我的⽂化背景和申请⽬标，⽣成符合这些要求的⽂书？", answer: "解答：我们的AI⽂书⼯具具有强⼤的⾃定义参数功能，⽤户可以输⼊与其⽂化背景、职业⽬标、申请院校等相关的具体信息，确保⽣成的⽂书能够贴合⽤户的独特需求。此外，系统⽀持多版本⽣成，⽤户可以从不同版本中选择最适合的表达⽅式。" },
  //     { question: "AI⽣成的内容是否能通过检测系统，是否会被怀疑为抄袭或模板化？", answer: "解答：我们的产品集成了AI降重功能，在⽤户对⽣成⽂书满意后，可以通过AI破解算法对⽂书进⾏humanize处理，使其变得更加⾃然、原创，确保⽂书在内容检测和审核中表现出⾼度的原创性和个性化。" },
  //     { question: "AI⽂书⼯具的价格是否合理，能否达到我期待的性价⽐？", answer: "解答：我们的AI⽂书⼯具相较于传统⽂书中介，能够以更低的价格提供⾼质量的⽂书⽣成和专家润⾊服务。通过⾃定义选择，⽤户可以根据需求⾃由选择功能，从基础版到⾼级版，灵活调整费⽤，确保产品具有良好的性价⽐。" },
  //     { question: "AI⼯具能否提供持续的⽀持，尤其是在申请过程中遇到修改需求时？", answer: "解答：我们的AI⽂书⼯具提供层层精修升级功能，包括⾃定义regenerate、expand、shorten等，⽤户可以在⽂书的各个阶段不断进⾏调整和优化。此外，系统7*24⼩时在线，随时为⽤户提供⽂书修改服务，确保⽤户在申请过程中得到持续的⽀持。" },
  //     { question: "AI⽣成的⽂书会不会存在语法或语⾔风格上的问题，影响申请效果？", answer: "我们的AI⽂书⼯具内置了类似于Grammarly的⽂书评估润⾊系统，确保⽤户可以⾃动检测并修正任何潜在的语法、拼写或语⾔风格问题。此外，最终⽂书可以通过⼈⼯润⾊编辑服务，由专业的语⾔专家进⾏最后的检查和优化，确保语⾔质量⽆懈可击。" },
  // ];

  return (
    <section className="faqs">
      <h2>常见问题</h2>
      {questions.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="question" onClick={() => toggleAccordion(index)}>
            {faq.question}
            <img src={require("../assets/images/arrow.png")} alt="" />
          </div>
          {openIndex === index && <div className="answer">{faq.answer}</div>}
        </div>
      ))}
      <a href="https://love.perfects.ai" target={"_blank"}>
        更多问题？
      </a>
    </section>
  );
};

export default FAQs;
